/* Spinner.css */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6); /* Semi-transparent white overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #00b0f0; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Others css */

  .selectable.desativado {
    background-color: #c2c2c2;
  }

  .desativado {
    background-color: #c2c2c2;
  }

  .selectable.selected {
    background-color: #00b0f0 !important; /* Altere a cor de fundo conforme necessário */
  }

  .selected {
    background-color: #00b0f0 !important; /* Altere a cor de fundo conforme necessário */
  }
  
  .items-menu .dropdown-item {
    padding: 0.25rem 1.5rem;
    color: #212529;
    text-decoration: none;
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .items-menu .dropdown-item:hover, .items-menu .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .btn-block {
    display: block;
    width: 100%;
  }
  
  .d-none {
    display: none;
  }
  
  .d-block {
    display: block;
  }

  
  
  .user-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    width: calc(100% - 2px);
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .user-list li {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
  }
  
  .user-list li:last-child {
    border-bottom: none;
  }
  
  .user-list li img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .add-user-icon {
    margin-left: auto;
    color: #007bff;
  }
  
  .add-user-icon:hover {
    color: #0056b3;
    cursor: pointer;
  }
  